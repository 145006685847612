import { render, staticRenderFns } from "./HomeBanner.vue?vue&type=template&id=45c7d940&scoped=true&"
import script from "./HomeBanner.vue?vue&type=script&lang=js&"
export * from "./HomeBanner.vue?vue&type=script&lang=js&"
import style0 from "./HomeBanner.vue?vue&type=style&index=0&id=45c7d940&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c7d940",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBanner,VImg})
