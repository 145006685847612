<template>
  <v-banner class="text-center">
    <a href="https://app.cardivo.com/giftcards/jambooks" target="_blank">
      <v-img :aspect-ratio="aspectRatio" :src="giftCard"></v-img>
    </a>
  </v-banner>
</template>

<script>
export default {
  name: 'HomeBanner',
  computed: {
    giftCard() {
      return require("@/assets/gift-card-ad.png");
    },
  }
}
</script>

<style scoped>
  .banner__text {
    font-size: 1.75rem;
    color: white;
    font-weight: bold;
  }
  @media only screen and (max-width: 600px) {
    .banner__text {
      font-size: 1rem;
    }
  }
</style>