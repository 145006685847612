<template>
  <v-container class="px-0 pb-0" fluid>
    <HomeBanner/>
    <HomeIntro />
    <HomeArm/>
    <SimpleText/>
    <Steps/>
    <HomeBooksBanner/>
    <WhyJamCard/>
    <WhyJambooks/>
    <ProductBanner/>
    <WaysToJamCard/>
    <HomeImageFooter/>
  </v-container>
</template>

<script>
import HomeIntro from '@/components/HomeIntro';
import WhyJamCard from '@/components/WhyJamCard';
import WaysToJamCard from '@/components/WaysToJamCard';
import HomeBanner from '@/components/HomeBanner';
import HomeArm from '@/components/HomeArm';
import SimpleText from '@/components/SimpleText';
import Steps from '@/components/Steps';
import WhyJambooks from '@/components/WhyJambooks';
import ProductBanner from '@/components/ProductBanner';
import HomeImageFooter from '@/components/HomeImageFooter';
import HomeBooksBanner from '@/components/HomeBooksBanner';
import { logout } from '@/services/authService';

  export default {
    name: 'Home',
    components: {
      HomeIntro,
      WhyJamCard,
      WaysToJamCard,
      HomeBooksBanner,
      HomeBanner,
      HomeArm,
      Steps,
      SimpleText,
      WhyJambooks,
      ProductBanner,
      HomeImageFooter,
    },
    beforeCreate () {
      if (this.$route.path === '/logout') {
        logout(this.$store);
        this.$router.push('/');
      }
    }
  }
</script>
