<template>
  <v-container>
    <LoginForm
        :loading="api.loading"
        :error="error"
        :errorMsg="errorMsg"
        @auth="handleAuth"
        @change="handleChange"
    />
  </v-container>
</template>

<script>
import LoginForm from '../components/LoginForm';
import Http from '@/api/Http';
import { mutationNames } from '@/store/global';

export default {
  name: 'Login',
  components: {
    LoginForm
  },
  data() {
    return {
      error: false,
      errorMsg: '',
      api: Http.use('data')
    }
  },
  methods: {
    handleAuth: async function(dt) {
      try {
        this.$gtag.event('login', { method: 'password' });
        this.api.post('/auth/login', {phone: dt.username, pass: dt.pass})
            .then(res => {
                this.$store.commit(mutationNames.UPDATE_CURRENT_USER, res.user);
                this.$store.commit(mutationNames.SET_STRIPE_KEY, res.stripe_key);
                if (this.$store.state.unauthPath) {
                    this.$router.push(this.$store.state.unauthPath);
                } else {
                    this.$router.push('/jams');
                }
            })
            .catch(err => {
              this.$store.commit(mutationNames.UPDATE_CURRENT_USER, false);
              this.errorMsg = err.response.data.error;
            });
      } catch (e) {
        this.errorMsg = e.error;
      }
    },
    handleChange: function() {
      this.errorMsg = '';
    }
  }
}
</script>
